import Chiara from "../objects/Chiara";
import LinkImage from "../objects/LinkImage";
import PopupImage from "../objects/PopupImage";
import { RoomObjectEvents } from "../objects/RoomObjectEvents";
import Globe from "../objects/Globe";
import WorldMapContainer from "../objects/WorldMapContainer";
import Trophy from "../objects/Trophy";
import TrophyContainer from "../objects/TrophyContainer";
import Console from "../objects/Console";
import ScreenContainer from "../objects/ScreenContainer";
import Sopor from "../objects/Sopor";
import Sootica from "../objects/Sootica";
import Menu from "../objects/Menu";
import Clock from "../objects/Clock";
import LinkSprite from "../objects/LinkSprite";
import Pointer = Phaser.Input.Pointer;
import { InteractiveText } from "../objects/InteractiveText";

export default class MainScene extends Phaser.Scene {
  private popupElement!:
    | Phaser.GameObjects.DOMElement
    | WorldMapContainer
    | TrophyContainer;
  private sopor!: Sopor;

  constructor() {
    super({ key: "MainScene" });
  }

  create() {

    let room = this.add.image(0, 0, "room");

    room.displayHeight = Number(this.sys.game.config.height);
    room.scaleX = Number(room.scaleY);

    room.y = Number(this.game.config.height) / 2;
    room.x = Number(this.game.config.width) / 2;

    this.add.image(0, 1150, "menu")
      .setOrigin(0, 1)
      .setDisplaySize(2250, 100);

    let group = new Phaser.GameObjects.Group(this);
    this.sopor = new Sopor(this);
    group.addMultiple([
      new LinkImage(
        this,
        1650,
        640,
        "facebook",
        "https://www.facebook.com/chiara.loqace.3",
      ).setScale(0.8, 0.8),
      new Clock(this),
      new Globe(this, 610, 200, "globe"),
      new PopupImage(this, 1110, 575, "gmail", "emailbox", "Contact me"),
      new LinkImage(
        this,
        1500,
        185,
        "linkedin",
        "https://www.linkedin.com/in/chiaraloqace/",
      ),
      new LinkImage(
        this,
        1810,
        290,
        "proz",
        "https://www.proz.com/feedback-card/1841692",
      ),
      new Trophy(this, 1120, 330, "trophy").setScale(1.3, 1.3),
      new LinkSprite(
        this,
        1680,
        330,
        "twitter",
        "Open",
        "https://bit.ly/twitter-chiaradm",
      ).setScale(4, 4),
      new LinkImage(
        this,
        1810,
        460,
        "upwork",
        "https://bit.ly/upwork-chiaradm",
      ),
      new Console(this, 145, 830, "console"),
      new Sootica(this),
      this.sopor,
      new Menu(this),
      new LinkSprite(this, 950, 400, "cv", "CV", "/assets/CV.pdf").setScale(
        5,
        5,
      ),
      new Chiara(this, "chiarabox"),
      new LinkSprite(this, 1400, 1000, "diary", "Coming soon...").setScale(
        5,
        5,
      ),
      new InteractiveText(
        this,
        50,
        1130,
        "About",
        "newDomElement",
        "chiarabox",
        "about"
      ),
      new InteractiveText(
        this,
        320,
        1130,
        "Talk to",
        "newDomElement",
        "emailbox",
        "contact",
      ),
      new InteractiveText(this, 590, 1130, "Portfolio", "showScreen", '', 'portfolio'),
      new InteractiveText(this, 860, 1130, "Reviews", "showTrophy", '', 'reviews'),
      new InteractiveText(this, 1130, 1130, "See map", "showMap", '', 'map'),
      new InteractiveText(
        this,
        1400,
        1130,
        "Timezone",
        "newDomElement",
        "clockbox",
        "timezone",
      ),
      new InteractiveText(
        this,
        1670,
        1130,
        "Read CV",
        "openResource",
        "/assets/CV.pdf",
      ),
      new InteractiveText(
        this,
        1940,
        1130,
        "Credits",
        "newDomElement",
        "creditsbox",
        'credits'
      ),
    ]);

    this.input.setHitArea(group.getChildren())
      .on(
        "gameobjectdown",
        (pointer: Pointer, gameObject: RoomObjectEvents) =>
          gameObject.pointerDown(),
      )
      .on(
        "gameobjectover",
        (pointer: Pointer, gameObject: RoomObjectEvents) =>
          gameObject.pointerOver(),
      )
      .on(
        "gameobjectout",
        (pointer: Pointer, gameObject: RoomObjectEvents) =>
          gameObject.pointerOut(),
      );

    this.anims.create(
      {
        key: "computer-blink",
        frames: this.anims.generateFrameNames(
          "computer",
          { prefix: "computer", start: 1, end: 2 },
        ),
        repeat: -1,
        frameRate: 1,
      },
    );
    this.add.sprite(1040, 805, "computer")
      .anims.play("computer-blink");

    this.events.on("newDomElement", (elementName: string) => {
      this.popupElement = this.add.dom(1125, 1040)
        .setOrigin(0.5, 1)
        .createFromCache(elementName)
        .setVisible(true);
      this.input.setHitArea(group.getChildren())
        .off("gameobjectdown")
        .off("gameobjectover")
        .off("gameobjectout");
    });

    this.events.on("openResource", (resourceName: string) => {
      if (resourceName) {
        window.open(resourceName, "_blank");
      }
    });

    this.events.on("showMap", () => {
      this.popupElement = new WorldMapContainer(this);
      this.input.setHitArea(group.getChildren())
        .off("gameobjectdown")
        .off("gameobjectover")
        .off("gameobjectout");
    });

    this.events.on("showTrophy", () => {
      this.popupElement = new TrophyContainer(this);
      this.input.setHitArea(group.getChildren())
        .off("gameobjectdown")
        .off("gameobjectover")
        .off("gameobjectout");
    });

    this.events.on("showScreen", () => {
      this.popupElement = new ScreenContainer(this);
      this.input.setHitArea(group.getChildren())
        .off("gameobjectdown")
        .off("gameobjectover")
        .off("gameobjectout");
    });

    this.input.on("pointerdown", () => {
      if (this.popupElement?.visible) {
        this.popupElement.destroy();
        history.back();
        group.getChildren().forEach((o) => {
          (o as unknown as RoomObjectEvents).pointerOut();
        });
        this.input.setHitArea(group.getChildren())
          .on(
            "gameobjectdown",
            (pointer: Pointer, gameObject: RoomObjectEvents) =>
              gameObject.pointerDown(),
          )
          .on(
            "gameobjectover",
            (pointer: Pointer, gameObject: RoomObjectEvents) =>
              gameObject.pointerOver(),
          )
          .on(
            "gameobjectout",
            (pointer: Pointer, gameObject: RoomObjectEvents) =>
              gameObject.pointerOut(),
          );
      }
    });

    const requestedPath = window.location.pathname;
    if (requestedPath) {
      history.replaceState({}, '', '/');
      switch (requestedPath) {
        case '/about':
          history.pushState({}, '', 'about');
          this.events.emit("newDomElement", "chiarabox");
          break;
        case '/contact':
          history.pushState({}, '', 'contact');
          this.events.emit("newDomElement", "emailbox");
          break;
        case '/portfolio':
          history.pushState({}, '', 'portfolio');
          this.events.emit('showScreen');
          break;
        case '/reviews':
          history.pushState({}, '', 'reviews');
          this.events.emit('showTrophy');
          break;
        case '/map':
          history.pushState({}, '', 'map');
          this.events.emit('showMap');
          break;
        case '/timezone':
          history.pushState({}, '', 'timezone');
          this.events.emit("newDomElement", "clockbox");
          break;
        case '/credits':
          history.pushState({}, '', 'credits');
          this.events.emit("newDomElement", "creditsbox");
          break;
      }
    }
  }

  update() {
    this.sopor.update(this.input.mousePointer);
  }
}
