import { RoomObjectEvents } from "./RoomObjectEvents";

export default class Menu extends Phaser.GameObjects.Image
  implements RoomObjectEvents {
  private readonly pointerDownImpl: () => void;
  private readonly rectangle: Phaser.GameObjects.Rectangle;

  constructor(scene: Phaser.Scene) {
    super(scene, 2210, 60, "menu");
    this.setVisible(false);
    this.setScale(0.2, 0.2);

    // scene.add.rectangle(2075, 50, 370, 260, 0x666666);
    scene.add.existing(this);
    scene.add.image(2050, 60, "logo").setScale(0.15, 0.15);
    scene.add.text(
      1870,
      110,
      "  Your English to Italian\nvideo games Loc + QA Ace",
      {
        fontFamily: "pixel_locale, monospace",
        fontSize: "24px",
        color: "#fff",
        stroke: "#000",
        strokeThickness: 7,
      },
    );

    this.rectangle = scene.add.rectangle(2075, 640, -350, 1040, 0x555555);
    this.rectangle.height = 0;

    this.pointerDownImpl = () => {
      if (this.rectangle.height === 0) {
        scene.tweens.add({
          targets: this.rectangle,
          height: 1040,
          ease: "Sine.easeInOut",
          duration: 400,
        });
      }
      if (this.rectangle.height === 1040) {
        scene.tweens.add({
          targets: this.rectangle,
          height: 0,
          ease: "Sine.easeInOut",
          duration: 400,
        });
      }
    };
  }

  pointerOver = (): void => {
    this.setTint(0xdedede);
    this.scene.game.canvas.style.cursor = "pointer";
  };

  pointerOut = (): void => {
    this.clearTint();
    this.scene.game.canvas.style.cursor = "default";
  };

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
