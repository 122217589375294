import { InteractiveSprite } from "./InteractiveSprite";

export default class Sootica extends InteractiveSprite {
  private readonly pointerDownImpl: () => void;

  constructor(scene: Phaser.Scene) {
    super(scene, 2020, 780, "sootica", {
      x: 2000,
      y: 850,
      text: "MIAAA'",
      targetEvent: "down",
    });

    scene.anims.create(
      {
        key: "sootica-move",
        frames: scene.anims.generateFrameNames(
          "sootica",
          { prefix: "cat", start: 1, end: 2 },
        ),
        repeat: -1,
        frameRate: 1,
      },
    );

    this.anims.play("sootica-move");

    this.pointerDownImpl = () => {
      if (this.tooltipOnDown) {
        this.tooltip?.setVisible(true);
        setTimeout(() => this.tooltip?.setVisible(false), 1000);
      }
    };
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
