import TrophyBox from "./TrophyBox";

export default class TrophyContainer extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0);
    this.setDepth(2);
    scene.add.existing(this);

    let textElement = scene.add.dom(1150, 570)
      .createFromCache("trophies")
      .setVisible(true);

    this.add([
      new TrophyBox(scene),
      textElement,
    ]);
  }
}
