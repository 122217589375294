import { InteractiveSprite } from "./InteractiveSprite";

export default class Clock extends InteractiveSprite {
  private readonly pointerDownImpl: () => void;

  constructor(scene: Phaser.Scene) {
    super(scene, 950, 200, "clock", {
      x: 1020,
      y: 110,
      text: "Timezone &\navailability",
      targetEvent: "over",
    });

    scene.anims.create(
      {
        key: "clock-move",
        frames: scene.anims.generateFrameNames(
          "clock",
          { prefix: "clock", start: 1, end: 3 },
        ),
        repeat: -1,
        frameRate: 2,
        yoyo: true,
      },
    );

    this.anims.play("clock-move");

    this.pointerDownImpl = () => {
      history.pushState({}, '', 'timezone');
      setTimeout(() => scene.events.emit("newDomElement", "clockbox"), 100);
    }
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
