import { RoomObjectEvents } from "./RoomObjectEvents";

const style = {
  fontFamily: "pixel_locale, monospace",
  fontSize: "40px",
  color: "#fff",
  stroke: "#000",
  strokeThickness: 7,
};
export class InteractiveText extends Phaser.GameObjects.Text
  implements RoomObjectEvents {
  private readonly pointerDownImpl: () => void;

  public constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    text: string,
    event?: string,
    args?: string,
    url?: string,
  ) {
    super(scene, x, y, text, style);

    this.setOrigin(0, 1);

    scene.add.existing(this);

    if (event) {
      this.pointerDownImpl = () => {
        if (url) history.pushState({}, '', url);
        setTimeout(() => scene.events.emit(event, args), 100);
      }
    } else {
      this.pointerDownImpl = () => {};
    }
  }

  pointerOver = (): void => {
    this.scene.game.canvas.style.cursor = "pointer";
  };

  pointerOut = (): void => {
    this.scene.game.canvas.style.cursor = "default";
  };

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
