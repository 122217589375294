import { RoomObjectEvents } from "./RoomObjectEvents";
import { Tooltip } from "./Tooltip";

export abstract class InteractiveSprite extends Phaser.GameObjects.Sprite
  implements RoomObjectEvents {
  protected tooltip: Tooltip | null;
  private readonly tooltipOnOver: boolean = false;
  protected readonly tooltipOnDown: boolean = false;

  protected constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    tooltip?: { x: number; y: number; text: string; targetEvent: string },
  ) {
    super(scene, x, y, texture);

    scene.add.existing(this);
    if (tooltip) {
      this.tooltip = new Tooltip(scene, tooltip.x, tooltip.y, tooltip.text);
      this.tooltip.setVisible(false);
      this.tooltipOnOver = tooltip.targetEvent === "over";
      this.tooltipOnDown = tooltip.targetEvent === "down";
    } else {
      this.tooltip = null;
    }
  }

  pointerOver = (): void => {
    this.setTint(0xdedede);
    if (this.tooltipOnOver) {
      this.tooltip?.setVisible(true);
    }
    this.scene.game.canvas.style.cursor = "pointer";
  };

  pointerOut = (): void => {
    this.resetPipeline();
    this.clearTint();
    if (this.tooltipOnOver) {
      this.tooltip?.setVisible(false);
    }
    this.scene.game.canvas.style.cursor = "default";
  };

  abstract pointerDown(): void;
}
