import POINTER_DOWN = Phaser.Input.Events.POINTER_DOWN;
import Pointer = Phaser.Input.Pointer;

export default class TrophyBox extends Phaser.GameObjects.Image {
  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0, "trophybox");

    this.displayHeight = Number(scene.sys.game.config.height) * 0.85;
    this.scaleX = Number(this.scaleY);

    this.y = Number(scene.game.config.height) / 2;
    this.x = Number(scene.game.config.width) / 2;

    scene.add.existing(this);

    this.setInteractive();
    this.on(
      POINTER_DOWN,
      (
        pointer: Pointer,
        x: number,
        y: number,
        event: Phaser.Types.Input.EventData,
      ) => {
        event.stopPropagation();
      },
      this,
    );
  }
}
