import { InteractiveImage } from "./InteractiveImage";

export default class Console extends InteractiveImage {
  private readonly pointerDownImpl: () => void;

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string) {
    super(scene, x, y, texture, "Portfolio &\nPartners");

    this.pointerDownImpl = () => {
      history.pushState({}, '', 'portfolio');
      setTimeout(() => scene.events.emit("showScreen"), 100);
    }
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
