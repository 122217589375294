import Pointer = Phaser.Input.Pointer;
import POINTER_OVER = Phaser.Input.Events.POINTER_OVER;
import POINTER_OUT = Phaser.Input.Events.POINTER_OUT;
import WorldMapContainer from "./WorldMapContainer";

export default class PinPoint extends Phaser.GameObjects.Image {
  constructor(
    scene: Phaser.Scene,
    container: WorldMapContainer,
    x: number,
    y: number,
    name: string,
  ) {
    super(scene, x, y, "pinpoint");

    this.displayHeight = 70;
    this.scaleX = Number(this.scaleY);

    this.setInteractive();

    const tooltip = scene.add.dom(x, y)
      .createFromCache(name)
      .setVisible(false);

    this.on(
      POINTER_OVER,
      (
        pointer: Pointer,
        x: number,
        y: number,
        event: Phaser.Types.Input.EventData,
      ) => {
        this.pointerOver(tooltip);
      },
      this,
    );
    this.on(
      POINTER_OUT,
      (
        pointer: Pointer,
        x: number,
        y: number,
        event: Phaser.Types.Input.EventData,
      ) => {
        this.pointerOut(tooltip);
      },
      this,
    );
  }

  pointerOver = (tooltip: Phaser.GameObjects.DOMElement): void => {
    this.setTint(0xdedede);
    tooltip.setVisible(true);
  };

  pointerOut = (tooltip: Phaser.GameObjects.DOMElement): void => {
    this.resetPipeline();
    this.clearTint();
    tooltip.setVisible(false);
  };
}
