import { InteractiveImage } from "./InteractiveImage";

export default class Globe extends InteractiveImage {
  private readonly pointerDownImpl: () => void;

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string) {
    super(scene, x, y, texture, "My places");

    this.setScale(1.3, 1.3);

    this.pointerDownImpl = () => {
      history.pushState({}, '', 'map');
      setTimeout(() => scene.events.emit("showMap"), 100);
    }
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
