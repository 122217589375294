import { InteractiveImage } from "./InteractiveImage";

export default class LinkImage extends InteractiveImage {
  private readonly link;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    link: string,
  ) {
    super(scene, x, y, texture, "Open");

    this.link = link;
  }

  pointerDown = () => {
    if (this.link) {
      window.open(this.link, "_blank");
    }
  };
}
