import ScreenBox from "./ScreenBox";

export default class ScreenContainer extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0);
    this.setDepth(2);
    scene.add.existing(this);

    let textElement = scene.add.dom(1125, 580)
      .createFromCache("games")
      .setVisible(true);

    this.add([
      new ScreenBox(scene),
      textElement,
    ]);
  }
}
