import { InteractiveSprite } from "./InteractiveSprite";

export default class Sopor extends InteractiveSprite {
  private readonly pointerDownImpl: () => void;
  private eyes: Phaser.GameObjects.Image;

  constructor(scene: Phaser.Scene) {
    super(scene, 352, 773, "sopor", {
      x: 320,
      y: 640,
      text: "MEEEOW!",
      targetEvent: "down",
    });

    scene.anims.create(
      {
        key: "sopor-move",
        frames: scene.anims.generateFrameNames(
          "sopor",
          { prefix: "cat", start: 1, end: 2 },
        ),
        repeat: -1,
        frameRate: 2,
      },
    );
    this.anims.play("sopor-move");

    this.eyes = scene.add.image(322, 743, "sopor-eyes");

    this.pointerDownImpl = () => {
      if (this.tooltipOnDown) {
        this.tooltip?.setVisible(true);
        setTimeout(() => this.tooltip?.setVisible(false), 1000);
      }
    };
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }

  update(pointer: { x: number; y: number }) {
    //x min: 322, max: 332
    if (pointer.x <= 322) {
      this.eyes.x = 322;
    } else if (pointer.x >= 332) {
      this.eyes.x = 332;
    } else if (pointer.x > 322 && pointer.x < 332) {
      this.eyes.x = pointer.x;
    }

    //y min: 738, max: 743
    if (pointer.y <= 738) {
      this.eyes.y = 738;
    } else if (pointer.y >= 743) {
      this.eyes.y = 743;
    } else if (pointer.y > 738 && pointer.y < 743) {
      this.eyes.y = pointer.y;
    }
  }
}
