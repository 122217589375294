import { InteractiveSprite } from "./InteractiveSprite";

export default class Chiara extends InteractiveSprite {
  private readonly pointerDownImpl: () => void;

  constructor(scene: Phaser.Scene, domElementName: string) {
    super(scene, 770, 740, "chiara", {
      x: 880,
      y: 510,
      text: "Chiara",
      targetEvent: "over",
    });

    scene.anims.create(
      {
        key: "chiara-pet",
        frames: scene.anims.generateFrameNames(
          "chiara",
          { prefix: "chiara", start: 0, end: 3 },
        ),
        repeat: -1,
        frameRate: 2.5,
      },
    );
    scene.add.image(810, 750, "chiara-chair").setScale(5, 5);
    scene.add.image(1075, 940, "chiara-shadow");

    this.setScale(0.85, 0.85);
    this.setDepth(1);

    this.anims.play("chiara-pet");

    this.pointerDownImpl = () => {
      history.pushState({}, '', 'about');
      setTimeout(() => scene.events.emit("newDomElement", domElementName), 100);
    }
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
