import { Scene } from "phaser";

const style = {
  fontFamily: "pixel_locale, monospace",
  fontSize: "35px",
  color: "#fff",
  stroke: "#000",
  strokeThickness: 8,
};

export class Tooltip extends Phaser.GameObjects.Text {
  constructor(scene: Scene, x: number, y: number, text: string) {
    super(scene, x, y, text, style);

    scene.add.existing(this);
  }
}
