import { RoomObjectEvents } from "./RoomObjectEvents";
import { Tooltip } from "./Tooltip";

export abstract class InteractiveImage extends Phaser.GameObjects.Image
  implements RoomObjectEvents {
  private tooltip: Tooltip | null;

  protected constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    tooltip?: string,
  ) {
    super(scene, x, y, texture);

    scene.add.existing(this);
    if (tooltip) {
      this.tooltip = new Tooltip(
        scene,
        x,
        y - this.displayHeight / 6 * 5,
        tooltip,
      );
      this.tooltip.setVisible(false);
    } else {
      this.tooltip = null;
    }
  }

  pointerOver = (): void => {
    this.setTint(0xdedede);
    if (!this.texture.key.endsWith("-hl")) {
      this.setTexture(this.texture.key + "-hl");
    }
    this.tooltip?.setVisible(true);
    this.scene.game.canvas.style.cursor = "pointer";
  };

  pointerOut = (): void => {
    this.clearTint();
    if (this.texture.key.endsWith("-hl")) {
      this.setTexture(this.texture.key.replace("-hl", ""));
    }
    this.tooltip?.setVisible(false);
    this.scene.game.canvas.style.cursor = "default";
  };

  abstract pointerDown(): void;
}
