import "phaser";
import MainScene from "./scenes/MainScene";
import PreloadScene from "./scenes/PreloadScene";

const DEFAULT_WIDTH = 2250;
const DEFAULT_HEIGHT = 1150;

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  backgroundColor: "#ffffff",
  scale: {
    parent: "phaser-game",
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: DEFAULT_WIDTH,
    height: DEFAULT_HEIGHT,
  },
  render: {
    pixelArt: true,
  },
  scene: [PreloadScene, MainScene],
  parent: "phaser-game",
  dom: {
    createContainer: true,
  },
};

window.addEventListener("load", () => {
  const game = new Phaser.Game(config);
});
