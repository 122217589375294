export default class PreloadScene extends Phaser.Scene {
  constructor() {
    super({ key: "PreloadScene" });
  }

  preload() {
    this.load.image("room", "assets/img/room.png");
    this.load.image("logo", "assets/img/LoQAce.png");
    this.load.image("menu", "assets/img/menubg.png");

    this.load.image("trophybox", "assets/img/trophybox.png");
    this.load.image("screen", "assets/img/screen.png");
    this.load.image("worldmap", "assets/img/worldmap.png");
    this.load.image("pinpoint", "assets/img/pin.png");
    this.load.image("diary", "assets/img/diary.png");
    this.load.image("cv", "assets/img/CV.png");
    this.load.image("twitter", "assets/img/twitter.png");

    this.load.image("facebook", "assets/img/elements/Facebook.png");
    this.load.image("globe", "assets/img/elements/Globe.png");
    this.load.image("gmail", "assets/img/elements/GMAIL.png");
    this.load.image("linkedin", "assets/img/elements/LinkedIN.png");
    this.load.image("proz", "assets/img/elements/Proz.png");
    this.load.image("trophy", "assets/img/elements/Trophy.png");
    this.load.image("upwork", "assets/img/elements/Upwork.png");
    this.load.image("console", "assets/img/elements/PS.png");

    this.load.image(
      "facebook-hl",
      "assets/img/elements/highlighted/Facebook.png",
    );
    this.load.image("globe-hl", "assets/img/elements/highlighted/Globe.png");
    this.load.image("gmail-hl", "assets/img/elements/highlighted/GMAIL.png");
    this.load.image(
      "linkedin-hl",
      "assets/img/elements/highlighted/LinkedIN.png",
    );
    this.load.image("proz-hl", "assets/img/elements/highlighted/Proz.png");
    this.load.image("trophy-hl", "assets/img/elements/highlighted/Trophy.png");
    this.load.image("upwork-hl", "assets/img/elements/highlighted/Upwork.png");
    this.load.image("console-hl", "assets/img/elements/highlighted/PS.png");

    this.load.html("emailbox", "assets/html/email.html");
    this.load.html("creditsbox", "assets/html/credits.html");
    this.load.html("clockbox", "assets/html/clock.html");
    this.load.html("chiarabox", "assets/html/chiara.html");
    this.load.html("montreal", "assets/html/montreal.html");
    this.load.html("malaga", "assets/html/malaga.html");
    this.load.html("cluj", "assets/html/cluj.html");
    this.load.html("quimper", "assets/html/quimper.html");
    this.load.html("italy", "assets/html/italy.html");
    this.load.html("tokyo", "assets/html/tokyo.html");
    this.load.html("trophies", "assets/html/trophies.html");
    this.load.html("games", "assets/html/games.html");

    this.load.atlas(
      "chiara",
      "assets/img/avatar/chiara.png",
      "assets/img/avatar/chiara_atlas.json",
    );
    this.load.image("chiara-shadow", "assets/img/avatar/chiara_shadow.png");
    this.load.image("chiara-chair", "assets/img/avatar/chiara_chair.png");
    this.load.atlas(
      "clock",
      "assets/img/clock/clock.png",
      "assets/img/clock/clock_atlas.json",
    );
    this.load.atlas(
      "sootica",
      "assets/img/sootica/sootica.png",
      "assets/img/sootica/sootica_atlas.json",
    );
    this.load.atlas(
      "sopor",
      "assets/img/sopor/sopor.png",
      "assets/img/sopor/sopor_atlas.json",
    );
    this.load.image("sopor-eyes", "assets/img/sopor/sopor_eyes.png");
    this.load.atlas(
      "computer",
      "assets/img/computer/computer.png",
      "assets/img/computer/computer_atlas.json",
    );
  }

  create() {
    this.scene.start("MainScene");

    /**
     * This is how you would dynamically import the mainScene class (with code splitting),
     * add the mainScene to the Scene Manager
     * and start the scene.
     * The name of the chunk would be 'mainScene.chunk.js
     * Find more about code splitting here: https://webpack.js.org/guides/code-splitting/
     */
    // let someCondition = true
    // if (someCondition)
    //   import(/* webpackChunkName: "mainScene" */ './mainScene').then(mainScene => {
    //     this.scene.add('MainScene', mainScene.default, true)
    //   })
    // else console.log('The mainScene class will not even be loaded by the browser')
  }
}
