import { InteractiveSprite } from "./InteractiveSprite";

export default class LinkSprite extends InteractiveSprite {
  private readonly link;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    text: string,
    link?: string,
  ) {
    super(
      scene,
      x,
      y,
      texture,
      { x: x + 50, y: y, text: text, targetEvent: "over" },
    );

    this.link = link;
  }

  pointerDown = () => {
    if (this.link) {
      window.open(this.link, "_blank");
    }
  };
}
