import WorldMap from "./WorldMap";
import PinPoint from "./PinPoint";

export default class WorldMapContainer extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene) {
    super(scene, 0, 0);
    this.setDepth(2);
    scene.add.existing(this);

    this.add([
      new WorldMap(scene, this),
      new PinPoint(scene, this, 685, 330, "montreal"),
      new PinPoint(scene, this, 1030, 335, "quimper"),
      new PinPoint(scene, this, 1100, 360, "italy"),
      new PinPoint(scene, this, 1160, 350, "cluj"),
      new PinPoint(scene, this, 1010, 395, "malaga"),
      new PinPoint(scene, this, 1780, 390, "tokyo"),
    ]);
  }
}
