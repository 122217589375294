import { InteractiveImage } from "./InteractiveImage";

export default class PopupImage extends InteractiveImage {
  private readonly pointerDownImpl: () => void;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    domElementName?: string,
    tooltip?: string,
  ) {
    super(scene, x, y, texture, tooltip);
    if (domElementName) {
      this.pointerDownImpl = () => {
        history.pushState({}, '', 'contact');
        setTimeout(() => scene.events.emit("newDomElement", domElementName), 100,);
      }
    } else {
      this.pointerDownImpl = () => {};
    }
  }

  pointerDown(): void {
    this.pointerDownImpl();
  }
}
